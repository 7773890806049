.slick-dots {
	bottom: -60px;
}

.slick-dots li.slick-active button:before {
	color: var(--DarkGreen);
	font-size: 1.4rem;
}

.slick-dots li button:before {
	color: #474747;
	font-size: 1rem;
}

@media (max-width: 600px) {
	.slick-dots {
		bottom: -60px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 1.4rem;
	}

	.slick-dots li button:before {
		font-size: 0.7rem;
	}
}

@media (min-width: 601px) and (max-width: 1024px) {
	.slick-dots {
		bottom: -60px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 1rem;
	}
	.slick-dots li button:before {
		font-size: 0.6rem;
	}
}

@media (min-width: 1900px) {
	.slick-dots {
		bottom: -80px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 3rem;
	}
	.slick-dots li button:before {
		font-size: 2.5rem;
	}
	.slick-dots li {
		margin-left: 2rem;
	}
}
