* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--DarkGreen: #0cb009;
	--LightGreen: #eefcee;
	--SecondaryBackground: #f1f7fc;
	--LightTextColor: #fff6f6;
	--GreyDescriptionColor: #7b7676;
	--ButtonColor: #f48b0e;
	--ButtonHoverColor: #fca438;
}

body {
	background-color: var(--LightGreen);
	overflow-x: hidden;
	font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
